// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start();

//#####################################################################################################
//# BOOTSTRAP
//#####################################################################################################

require("@popperjs/core");

import "bootstrap";

import { Tooltip, Popover } from "bootstrap";

require("./bootstrap");

//#####################################################################################################
//# JQUERY
//#####################################################################################################

require("jquery");

//#####################################################################################################
//# CONTROLLERS
//#####################################################################################################

require("./frontend/jobs");

//#####################################################################################################
//# CUSTOMIZE
//#####################################################################################################

(function($) {
  "use strict";
  $(document).ready(function() {init();});

  const init = function() {
    close_flash_notice_alert();

    if ($('#flash_notice_alert,#flash_error_alert').length) $('#flash_notice_alert,#flash_error_alert').addClass('mt-3');
  };

})(jQuery);

//#####################################################################################################
// SLIDE UP NOTICE ALERT
//#####################################################################################################

const close_flash_notice_alert = function() {
  const $flash_notice_alert = $('#flash_notice_alert');
  setTimeout( function() { $flash_notice_alert.slideUp(); }, $flash_notice_alert.data('display_milliseconds'));
}
