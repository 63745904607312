import $ from 'jquery'
import jQuery from 'jquery'

import * as bootstrap from 'bootstrap'

import Cookies from 'js-cookie'

(function($) {
  "use strict";
  $(document).ready(function() {
    if ($('#jobs__register').length) {
      init_file_upload_handler();
      init_agreement_modal();
    }
  });

  const init_file_upload_handler = () => {
    const $resume_div = $('#resume_div');
    const $user_resume_id = $('#user_resume_id'); 
    const $resume_input_div = $('#resume_input_div');
    const $label = $resume_input_div.find('.custom-file-label');

    $('#user_resume').on('change', e => {
      $('#overlay').show();

      if ($(e.target).attr('accept').includes('image')) {
        set_image_thumb(e.target);
      }

      FileUploader.upload(e.target);
    });

    $('#remove_resume').on('click', e => {
      $user_resume_id.val('');
      $label.text('Choose file');
      toggle_file_fields();
    })

    const FileUploader = {
      upload: input => {
        const $input = $(input);

        var formData = new FormData();
        formData.append('file', input.files[0]);
        formData.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'));

        $.ajax({
          type: "POST",
          url: $input.data('url'),
          data: formData,
          dataType: 'json',
          processData: false,
          contentType: false
        }).done((data, textStatus, jqXHR) => {

          $user_resume_id.val(data.file_id)
          $label.text(input.files[0].name)
          $resume_input_div.find('#user_resume').val('');

          toggle_file_fields();
          $('#overlay').hide();

        }).fail((jqXHR, textStatus, errorThrown) => {
          if (confirm("Error. Reload the page.\n\"" + errorThrown + "\"")) location.reload();
        });

        return;
      }
    }

    const toggle_file_fields = () => {
      if ($user_resume_id.val()) {
        if ( ! $resume_div.hasClass('with')) $resume_div.addClass('with');
      } else {
        if ($resume_div.hasClass('with')) $resume_div.removeClass('with');
      }
    }

    toggle_file_fields();
  }

  const init_agreement_modal = function() {
    const cookie_name = 'terms_and_conditions_agreement_at_jobs';

    if (Cookies.get(cookie_name) == undefined) {
      const modal = new bootstrap.Modal(document.getElementById('agreement_modal'), { backdrop: 'static'})
      modal.show();

      $('#btn-agree').on('click', function() {
        Cookies.set(cookie_name, '1', { expires: 1 });
        modal.hide();
      });

      $('#btn-deny').on('click', function() {
        Cookies.remove(cookie_name);
        $('#agreement_modal .modal-body').html('<div id="spinner_div" class="text-center"><i class="fa-solid fa-spinner fa-spin-pulse fa-5x"></i></div>');
      });
    }

  }

})(jQuery);
