import * as bootstrap from 'bootstrap'

document.addEventListener("DOMContentLoaded", function(event) {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
});

// (function($) {
//   "use strict";
//   $(document).ready(function() {
//     if ($('#modal .modal-body').length) {
//       const reset_modal_tooltips = () => {
//         if ($('[data-bs-toggle="modal-tooltip"]').length) {
//           const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
//           const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
//         }
//       }
//
//       const observer = new MutationObserver(records => {
//         reset_modal_tooltips();
//       });
//
//       observer.observe($('#modal .modal-body')[0], { childList: true });
//     }
//   );
//
// })(jQuery);
